import React from 'react';

const Thanks = () => {
	return (
		<>
			<div className="form-container problem-section">
				<div className="img-wrapper">
					<img src="https://seiturju.sirv.com/Chatbiz/help-outlined-blue.svg" alt="done" />
				</div>

				<div className="title">Masih bermasalah?</div>
				<p className="desc">
					Sepertinya kamu memiliki kendala dalam mendaftar. Jangan Khawatir kami akan membantu kamu
					segera!
				</p>
				<div className="btn-wrapper">
					<a href="https://go.chatbiz.id/hubungi-kami">Hubungi Kami</a>
				</div>
			</div>
		</>
	);
};

export default Thanks;
