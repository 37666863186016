/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import useForm from '../../../hooks/useForm';
import {
	contactUsForm as ValidateContactUs,
	contactUsIsErrorFormPhone,
	contactUsIsErrorFormAgency,
	contactUsIsErrorFormFullName,
} from '../../../helper/formHelper';
import {
	ContactUsGeneratePhoneTextError,
	ContactUsGenerateAgencyTextError,
	ContactUsGenerateFullNameTextError,
} from '../../../helper/formValidation';
import {
	constanstMessage,
	getItem as getItemLocalStorage,
	setItem as setItemLocalStorage,
} from '../../../helper/localStorage';
import { orderAPI } from '../../../api/homepage';
import { LoadingEllipsisWhite } from './loadingEllipsis';

const initialValueForm = withInitialPhoneNumber => {
	const data = {
		phoneNumber: '',
	};

	if (withInitialPhoneNumber) {
		data.phoneNumber = withInitialPhoneNumber;
	}

	return data;
};

const Resend = ({ toProblemPage, setIsLoading, isLoading }) => {
	const [statusApi, setStatusApi] = useState('');
	const [statusApiError, setStatusApiError] = useState('');
	const [oldPhoneNumber, setOldPhoneNumber] = useState(
		getItemLocalStorage(constanstMessage.STORE_PHONE_NUMBER) || '',
	);
	useEffect(() => {
		setOldPhoneNumber(getItemLocalStorage(constanstMessage.STORE_PHONE_NUMBER) || '');
	}, []);

	const { values, errors, handleChange, handleSubmit } = useForm({
		initialValue: initialValueForm(oldPhoneNumber),
		handleSetInitialValue: initialValueForm(oldPhoneNumber),
		onSubmit: async dt => {
			if (!dt.isAnyErrors) {
				setIsLoading(true);
				try {
					const body = {
						whatsapp_number: String(dt.values.phoneNumber),
						name: getItemLocalStorage(constanstMessage.STORE_FULL_NAME),
						agency: getItemLocalStorage(constanstMessage.STORE_AGENCY_NAME),
					};
					const { data } = await orderAPI(body);
					if (data) {
						setStatusApi('API Successful');
						setIsLoading(false);
						toProblemPage();
					}
				} catch (e) {
					setStatusApi('API Failed');
					setStatusApiError(e.response.data.error);
					setIsLoading(false);
				}
			} else {
				console.log('errForm', dt);
			}
		},
		withCustomValidation: (errValue, formValue) => ValidateContactUs(errValue, formValue),
	});

	const isErrorFormPhone = () => {
		return contactUsIsErrorFormPhone(errors.phoneNumber, statusApiError);
	};

	const generatePhoneError = () => {
		return (
			<ContactUsGeneratePhoneTextError
				phoneNumber={errors.phoneNumber}
				statusApiError={statusApiError}
			/>
		);
	};

	return (
		<>
			<div className="form-container resend-section">
				<div className="img-wrapper">
					<img src="https://seiturju.sirv.com/Chatbiz/replay-outlined-blue.svg" alt="done" />
				</div>

				<div className="title">Kirim Ulang</div>
				<p className="desc">
					Pastikan nomor dibawah ini <span>benar dan sesuai</span> dengan nomor kamu{' '}
				</p>
				<form onSubmit={handleSubmit}>
					<div className="form-group">
						<input
							type="text"
							pattern="[0-9+-\s]*"
							id="nomorWhatsapp"
							placeholder="Contoh : 082282120xxx"
							name="phoneNumber"
							onChange={e => {
								handleChange(e);
								setItemLocalStorage(constanstMessage.STORE_PHONE_NUMBER, e.target.value);

								setStatusApiError('');
							}}
							value={values.phoneNumber}
							className={isErrorFormPhone() ? 'form-control invalidForm' : 'form-control'}
						/>

						<div className="btn-wrapper">
							<button type="submit" className="btn btn-primary" disabled={isLoading}>
								{isLoading ? <LoadingEllipsisWhite /> : <> Coba Lagi</>}
							</button>
						</div>
					</div>
					{isErrorFormPhone() && (
						<div className="txt-error">
							<div>{generatePhoneError()}</div>
						</div>
					)}
				</form>
			</div>
		</>
	);
};

Resend.propTypes = {
	toProblemPage: PropTypes.func.isRequired,
	setIsLoading: PropTypes.func.isRequired,
	isLoading: PropTypes.bool.isRequired,
};

export default Resend;
