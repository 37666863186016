/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import { useState, useRef, useEffect } from 'react';

function clearObjectValue(obj) {
	let newObj = {};
	for (const prop in obj) {
		newObj = {
			...newObj,
			[prop]: '',
		};
	}

	return newObj;
}

const useForm = ({ initialValue, onSubmit, withCustomValidation }) => {
	const [values, setValues] = useState(initialValue || {});
	const [touched, setTouched] = useState({});
	const [, setOnBlur] = useState(false);

	const [errors, setErrors] = useState(clearObjectValue(initialValue || {}));
	const [isAnyErrors, setIsAnyErrors] = useState(false);
	const [isOnSubmit, setOnSubmitting] = useState(false);
	const formRef = useRef(true);

	useEffect(() => {
		if (formRef.current) {
			setValues(initialValue);
			setTouched({});
			setOnBlur(false);
			setErrors(clearObjectValue(initialValue || {}));
			setOnSubmitting(false);
		}

		formRef.current = false;
	}, [initialValue]);

	const handleChange = e => {
		const { target } = e;
		const { name, value, validity } = target;
		if (validity.valid) {
			e.persist();
			const valuesDt = { ...values, [name]: value };
			setValues(valuesDt);
			let currentError = { ...errors, [name]: '' };
			let isAnyError = false;

			if (typeof withCustomValidation !== 'undefined' && (isOnSubmit || name === 'phoneNumber')) {
				const customValidation = withCustomValidation(currentError, valuesDt);
				currentError = customValidation.errValue;
				isAnyError = customValidation.isAnyError;
			}

			setErrors({ ...currentError });
			setIsAnyErrors(isAnyError);
		} else {
			setValues({ ...values, [name]: value });
			setErrors({ [name]: 'invalid-format' });
		}
	};

	const handleChangeCheckbox = e => {
		const { target } = e;
		const { name, value, validity } = target;
		if (validity.valid) {
			e.persist();

			let newVal = [...values[name], value];
			if (values[name].includes(value)) {
				newVal = newVal.filter(v => v !== value);
			}

			const valuesDt = { ...values, [name]: newVal };
			setValues(valuesDt);
			let currentError = { ...errors, [name]: '' };
			let isAnyError = false;

			if (typeof withCustomValidation !== 'undefined' && (isOnSubmit || name === 'phoneNumber')) {
				const customValidation = withCustomValidation(currentError, valuesDt);
				currentError = customValidation.errValue;
				isAnyError = customValidation.isAnyError;
			}

			setErrors({ ...currentError });
			setIsAnyErrors(isAnyError);
		} else {
			setValues({ ...values, [name]: value });
			setErrors({ [name]: 'invalid-format' });
		}
	};

	const handleChangeInject = (key, value) => {
		setValues({ ...values, [key]: value });
	};

	const handleChangeErrorInject = (key, value) => {
		setErrors({ ...errors, [key]: value });
		console.log('-aaa', key, value);
	};

	const handleBlur = e => {
		const { target } = e;
		const { name } = target;
		setTouched({ ...touched, [name]: true });
		setErrors({ ...errors });
	};

	const handleSubmit = e => {
		if (e) e.preventDefault();
		let currentError = errors;
		let currentIsAnyErrors = false;

		// for (const key in values) {
		// 	if (values[key] === '') {
		// 		currentError = { ...currentError, [key]: `required` };
		// 		currentIsAnyErrors = true;
		// 	}
		// }

		if (typeof withCustomValidation !== 'undefined') {
			const customValidation = withCustomValidation(currentError, values);
			currentError = customValidation.errValue;
			currentIsAnyErrors = customValidation.isAnyError;
		}

		setErrors({ ...currentError });
		setIsAnyErrors(currentIsAnyErrors);
		setOnSubmitting(true);
		onSubmit({ values, errors: currentError, isAnyErrors: currentIsAnyErrors });
	};

	const handleSetInitialValue = dataInitialValue => {
		setValues(dataInitialValue || {});
	};

	return {
		values,
		isAnyErrors,
		errors,
		touched,
		handleChange,
		handleChangeCheckbox,
		handleChangeInject,
		handleChangeErrorInject,
		handleBlur,
		handleSubmit,
		handleSetInitialValue,
		isOnSubmit,
	};
};

export default useForm;
