/* eslint-disable no-useless-escape */
/* eslint-disable no-param-reassign */

export const emailValidation = email => {
	const re = new RegExp(
		/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i,
	);
	if (!re.test(email)) return 'invalid-email';
	return '';
};

export const phoneValidation = phoneNumber => {
	if (phoneNumber === '') {
		return 'required';
	}
	const regexPhone = RegExp(`^[0-9+-\\s]+$`);
	if (!regexPhone.test(phoneNumber)) return 'invalid-phone-number';

	if (
		phoneNumber.substring(0, 2) !== '08' &&
		phoneNumber.substring(0, 2) !== '62' &&
		phoneNumber.substring(0, 3) !== '+62'
	) {
		return 'invalid-phone-number';
	}
	return '';
};

export const contactUsForm = (errValue, formValue) => {
	let isAnyError = false;

	const validation = phoneValidation(formValue.phoneNumber);
	if (validation !== '') {
		isAnyError = true;
		errValue.phoneNumber = validation;
	}
	if (formValue.agencyName === '') {
		isAnyError = true;
		errValue.agencyName = 'required';
	}

	if (formValue.fullName === '') {
		isAnyError = true;
		errValue.fullName = 'required';
	}
	return { errValue, isAnyError };
};

export const contactUsIsErrorFormPhone = (phoneNumber, statusApiError) => {
	return (
		phoneNumber === 'invalid-format' ||
		phoneNumber === 'invalid-phone-number' ||
		phoneNumber === 'required' ||
		statusApiError !== ''
	);
};

export const contactUsIsErrorFormAgency = agencyName => {
	return agencyName === 'required';
};

export const contactUsIsErrorFormFullName = fullName => {
	return fullName === 'required';
};
