/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import useForm from '../../../hooks/useForm';
import {
	contactUsForm as ValidateContactUs,
	contactUsIsErrorFormPhone,
	contactUsIsErrorFormAgency,
	contactUsIsErrorFormFullName,
} from '../../../helper/formHelper';
import {
	ContactUsGeneratePhoneTextError,
	ContactUsGenerateAgencyTextError,
	ContactUsGenerateFullNameTextError,
} from '../../../helper/formValidation';
import { constanstMessage, setItem as setItemLocalStorage } from '../../../helper/localStorage';
import { orderAPI } from '../../../api/homepage';
import { LoadingEllipsis } from './loadingEllipsis';

const initialValueForm = withInitialPhoneNumber => {
	const data = {
		phoneNumber: '',
		agencyName: '',
		fullName: '',
	};

	if (withInitialPhoneNumber) {
		data.phoneNumber = withInitialPhoneNumber;
	}

	return data;
};

const FormSection = ({ toThanksPage, setIsLoading, isLoading }) => {
	const [statusApi, setStatusApi] = useState('');
	const [statusApiError, setStatusApiError] = useState('');

	const { values, errors, handleChange, handleSubmit } = useForm({
		initialValue: initialValueForm(),
		onSubmit: async dt => {
			if (!dt.isAnyErrors) {
				setIsLoading(true);
				try {
					const body = {
						whatsapp_number: String(dt.values.phoneNumber),
						name: dt.values.fullName,
						agency: dt.values.agencyName,
					};
					const { data } = await orderAPI(body);
					if (data) {
						setStatusApi('API Successful');
						setIsLoading(false);
						toThanksPage();
					}
				} catch (e) {
					setStatusApi('API Failed');
					setStatusApiError(e.response.data.error);
					setIsLoading(false);
				}
			} else {
				console.log('errForm', dt);
			}
		},
		withCustomValidation: (errValue, formValue) => ValidateContactUs(errValue, formValue),
	});

	const isErrorFormPhone = () => {
		return contactUsIsErrorFormPhone(errors.phoneNumber, statusApiError);
	};

	const isErrorFormAgency = () => {
		return contactUsIsErrorFormAgency(errors.agencyName);
	};

	const isErrorFormFullName = () => {
		return contactUsIsErrorFormFullName(errors.fullName);
	};

	const generatePhoneError = () => {
		return (
			<ContactUsGeneratePhoneTextError
				phoneNumber={errors.phoneNumber}
				statusApiError={statusApiError}
			/>
		);
	};

	const generateAgencyError = () => {
		return <ContactUsGenerateAgencyTextError agencyName={errors.agencyName} />;
	};

	const generateFullNameError = () => {
		return <ContactUsGenerateFullNameTextError fullName={errors.fullName} />;
	};

	return (
		<div className="form-container">
			<div className="form-text-wrapper">
				<div className="title">Hubungi Kami</div>
				<div className="sub-title">
					Konsultasikan hal yang ingin bisnis anda tingkatkan bersama kami
				</div>
			</div>
			<form onSubmit={handleSubmit}>
				<div className="form-group">
					<label htmlFor="namaLengkapAnda">Nama Anda</label>
					<input
						type="text"
						className="form-control"
						id="namaLengkapAnda"
						placeholder="Contoh : Andi Purnama"
						name="fullName"
						onChange={e => {
							setItemLocalStorage(constanstMessage.STORE_FULL_NAME, e.target.value);
							handleChange(e);
						}}
						value={values.fullName}
						className={isErrorFormFullName() ? 'form-control invalidForm' : 'form-control'}
					/>
					{isErrorFormFullName() && (
						<div className="txt-error">
							<div>{generateFullNameError()}</div>
						</div>
					)}
				</div>
				<div className="form-group">
					<label htmlFor="namaInstansiAnda">Nama Instansi Anda</label>
					<input
						type="text"
						className="form-control"
						id="namaInstansiAnda"
						placeholder="Contoh : Chatbiz"
						name="agencyName"
						onChange={e => {
							setItemLocalStorage(constanstMessage.STORE_AGENCY_NAME, e.target.value);
							handleChange(e);
						}}
						value={values.agencyName}
						className={isErrorFormAgency() ? 'form-control invalidForm' : 'form-control'}
					/>
					{isErrorFormAgency() && (
						<div className="txt-error">
							<div>{generateAgencyError()}</div>
						</div>
					)}
				</div>
				<div className="form-group">
					<label htmlFor="nomorWhatsapp">Nomor WhatsApp</label>
					<input
						type="text"
						pattern="[0-9+-\s]*"
						id="nomorWhatsapp"
						placeholder="Contoh : 082282120xxx"
						name="phoneNumber"
						onChange={e => {
							setItemLocalStorage(constanstMessage.STORE_PHONE_NUMBER, e.target.value);
							handleChange(e);
							setStatusApiError('');
						}}
						value={values.phoneNumber}
						className={isErrorFormPhone() ? 'form-control invalidForm' : 'form-control'}
					/>
					{isErrorFormPhone() && (
						<div className="txt-error">
							<div>{generatePhoneError()}</div>
						</div>
					)}
				</div>

				<div className="btn-wrapper">
					<button type="submit" className="btn btn-primary" disabled={isLoading}>
						{isLoading ? <LoadingEllipsis /> : <>Kirim Permintaan</>}
					</button>
				</div>
			</form>
		</div>
	);
};

FormSection.propTypes = {
	toThanksPage: PropTypes.func.isRequired,
	setIsLoading: PropTypes.func.isRequired,
	isLoading: PropTypes.bool.isRequired,
};

export default FormSection;
