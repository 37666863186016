/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';

import Content from './content';
import FormContainer from './form';
import Thanks from './thanks';
import Resend from './resend';
import Problem from './problem';

const FormHubungiKami = () => {
	const [countThanks, setCountThanks] = useState(0);
	const [isForm, setIsForm] = useState(true);
	const [isThanks, setIsThanks] = useState(false);
	const [isResend, setIsResend] = useState(false);
	const [isProblem, setIsProblem] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	const toThanksPage = () => {
		setCountThanks(countThanks + 1);
		setIsForm(false);
		setIsProblem(false);
		setIsResend(false);
		setIsThanks(true);
	};

	const toResendPage = () => {
		setIsForm(false);
		setIsThanks(false);
		setIsProblem(false);
		setIsResend(true);
	};

	const toProblemPage = () => {
		if (countThanks > 2) {
			setIsForm(false);
			setIsThanks(false);
			setIsResend(false);
			setIsProblem(true);
		} else {
			toThanksPage();
		}
	};

	const renderCondition = () => {
		if (isProblem) return <Problem />;
		if (isForm)
			return (
				<FormContainer toThanksPage={toThanksPage} setIsLoading={setIsLoading} isLoading={isLoading} />
			);
		if (isThanks) return <Thanks toResendPage={toResendPage} />;
		if (isResend)
			return (
				<Resend toProblemPage={toProblemPage} setIsLoading={setIsLoading} isLoading={isLoading} />
			);

		return null;
	};

	return (
		<>
			<section className="page-form-hubungi-kami">
				<div className="description-wrapper">
					<Content />
				</div>
				<div className="form-wrapper">{renderCondition()}</div>
			</section>
		</>
	);
};

export default FormHubungiKami;
