import axios from 'axios';

const config = {
	baseURL: process.env.REACT_APP_API_BASE_URL,
};

export const chatbizCreate = axios.create({
	baseURL: process.env.REACT_APP_CHATBIZ_API_BASE_URL,
});

export default axios.create(config);
