/* eslint-disable no-param-reassign */
/* eslint-disable import/prefer-default-export */
import API from './index';

export const orderAPI = async body => {
	if (body.whatsapp_number[0] === '0') {
		body.whatsapp_number = `62${body.whatsapp_number.slice(1)}`;
	}
	if (body.whatsapp_number[0] === '+') {
		body.whatsapp_number = `62${body.whatsapp_number.slice(3)}`;
	}
	body.whatsapp_number = body.whatsapp_number.replace(/-/g, '');
	body.whatsapp_number = body.whatsapp_number.replace(/ /g, '');

	return API.post(`contact`, body);
};
