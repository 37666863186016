import React from 'react';

export const LoadingEllipsis = () => (
	<div className="loadingEllipsisContainer">
		<div className="ldsEllipsis">
			<div />
			<div />
			<div />
			<div />
		</div>
	</div>
);

export const LoadingEllipsisWhite = () => (
	<div className="loadingEllipsisContainer">
		<div className="ldsEllipsis">
			<div className="white" />
			<div className="white" />
			<div className="white" />
			<div className="white" />
		</div>
	</div>
);

export default LoadingEllipsis;
