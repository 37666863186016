import React from 'react';

const Content = () => {
	return (
		<div className="content-wrapper">
			<div className="logo-wrapper">
				<img src="https://seiturju.sirv.com/Chatbiz/logo.png" alt="logo" />
			</div>
			<div className="top-text-wrapper">
				<div className="text__sub--medium">
					Menyediakan layanan <span>Asisten Virtual</span> Chatbot Bahasa Indonesia dengan
				</div>
				<div className="text__title">
					Personalisasi dan Kustomisasi yang tinggi dengan harga terjangkau
				</div>
				<div className="text__sub--small">
					sehingga Anda dapat mengotomasi bisnis Anda dan meningkatkan pengalaman pelanggan dengan
					menawarkan interaksi pengguna yang lebih efisien
				</div>
			</div>
			<div className="list-wrapper">
				<div className="list-left">
					<ul>
						<li>
							<div className="img-container">
								<img src="https://seiturju.sirv.com/Chatbiz/done-circle-green.svg" alt="done" />
							</div>
							<div className="txt">Tanya jawab FAQ</div>
						</li>
						<li>
							<div className="img-container">
								<img src="https://seiturju.sirv.com/Chatbiz/done-circle-green.svg" alt="done" />
							</div>
							<div className="txt">Akun WhatsApp Verified</div>
						</li>
						<li>
							<div className="img-container">
								<img src="https://seiturju.sirv.com/Chatbiz/done-circle-green.svg" alt="done" />
							</div>
							<div className="txt">Broadcast Messaging</div>
						</li>
						<li>
							<div className="img-container">
								<img src="https://seiturju.sirv.com/Chatbiz/done-circle-green.svg" alt="done" />
							</div>
							<div className="txt">WhatsApp, Line, Telegram, Messenger.</div>
						</li>
						<li>
							<div className="img-container">
								<img src="https://seiturju.sirv.com/Chatbiz/done-circle-green.svg" alt="done" />
							</div>
							<div className="txt">Rekap otomatis</div>
						</li>
					</ul>
				</div>
				<div className="list-right">
					<ul>
						<li>
							<div className="img-container">
								<img src="https://seiturju.sirv.com/Chatbiz/done-circle-green.svg" alt="done" />
							</div>
							<div className="txt">Tanya jawab dengan Kecerdasan Buatan</div>
						</li>
						<li>
							<div className="img-container">
								<img src="https://seiturju.sirv.com/Chatbiz/done-circle-green.svg" alt="done" />
							</div>
							<div className="txt">Dashboard Omnichannel</div>
						</li>
						<li>
							<div className="img-container">
								<img src="https://seiturju.sirv.com/Chatbiz/done-circle-green.svg" alt="done" />
							</div>
							<div className="txt">1 Akun untuk banyak Agen</div>
						</li>
						<li>
							<div className="img-container">
								<img src="https://seiturju.sirv.com/Chatbiz/done-circle-green.svg" alt="done" />
							</div>
							<div className="txt">Website katalog produk terintegrasi</div>
						</li>
						<li>
							<div className="img-container">
								<img src="https://seiturju.sirv.com/Chatbiz/done-circle-green.svg" alt="done" />
							</div>
							<div className="txt">Analytics</div>
						</li>
					</ul>
				</div>
			</div>
			<div className="txt-link">
				<a href="/" rel="noreferrer" target="_blank">
					Pelajari Lebih Lengkap
				</a>
			</div>

			<div className="bottom-text-wrapper">
				<div className="title">Kami telah dipercaya oleh</div>
				<div className="list-image-wrapper">
					<div className="container">
						<div className="row">
							<div className="col-6 col-sm-6 col-md-4 client">
								<img src="https://seiturju.sirv.com/Chatbiz/client/bi.webp" alt="rumah zakat" />
							</div>
							<div className="col-6 col-sm-6 col-md-4 client">
								<img src="https://seiturju.sirv.com/Chatbiz/client/manulife.webp" alt="manulife" />
							</div>
							<div className="col-6 col-sm-6 col-md-4 client">
								<img
									src="https://seiturju.sirv.com/Chatbiz/baraya-travel.png?format=webp"
									alt="baraya-travel"
								/>
							</div>
							<div className="col-6 col-sm-6 col-md-4 client">
								<img
									src="https://seiturju.sirv.com/Chatbiz/Kino_Indonesia_logo.webp?format=webp"
									alt="kino"
								/>
							</div>
							<div className="col-6 col-sm-6 col-md-4 client">
								<img
									src="https://seiturju.sirv.com/Chatbiz/rumah-logo.png?format=webp"
									alt="sharinghapinnes"
								/>
							</div>
							<div className="col-6 col-sm-6 col-md-4 client">
								<img src="https://seiturju.sirv.com/Chatbiz/solusindo.png?format=webp" alt="solusindo" />
							</div>

							<div className="col-6 col-sm-6 col-md-3 client">
								<img src="https://seiturju.sirv.com/Chatbiz/client/edenfarm.webp" alt="edenfarm" />
							</div>
							<div className="col-6 col-sm-6 col-md-2 client">
								<img src="https://seiturju.sirv.com/Chatbiz/issa-logo.png?format=webp" alt="lisa" />
							</div>
							<div className="col-6 col-sm-6 col-md-3 client">
								<img src="https://seiturju.sirv.com/Chatbiz/client/bhineka.webp" alt="shuttle" />
							</div>
							<div className="col-6 col-sm-6 col-md-3 client">
								<img src="https://seiturju.sirv.com/Chatbiz/client/bajuyuli_2.webp" alt="bajuyuli" />
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="txt-link">
				<a href="/blog/kategori/portofolio" rel="noreferrer" target="_blank">
					Lihat Portofolio Kami
				</a>
			</div>
		</div>
	);
};

export default Content;
