import React from 'react';
import PropTypes from 'prop-types';

const Thanks = ({ toResendPage }) => {
	return (
		<>
			<div className="form-container thanks-section">
				<div className="img-wrapper">
					<img src="https://seiturju.sirv.com/Chatbiz/done_outlined-blue.svg" alt="done" />
				</div>

				<div className="title">
					Silakan periksa <span>WhatsApp Anda</span>
				</div>
				<p className="desc">Terima Kasih, silakan cek pesan baru dari kami di WhatsApp kamu.</p>
				<p className="try">
					Tidak menerima pesan ?{' '}
					<button
						type="button"
						onClick={() => {
							toResendPage();
						}}
					>
						Coba Lagi
					</button>
				</p>
			</div>
		</>
	);
};

Thanks.propTypes = {
	toResendPage: PropTypes.func.isRequired,
};

export default Thanks;
