/* eslint-disable react/prop-types */
/* eslint-disable import/prefer-default-export */
import React from 'react';

export const ContactUsGeneratePhoneTextError = ({ phoneNumber, statusApiError }) => {
	if (phoneNumber === 'invalid-format' || phoneNumber === 'invalid-phone-number') {
		return (
			<>
				Sepertinya ini <span>bukan nomor yang valid,</span> pastikan diawali +62xxxx, 62xxx atau 08xxx.
			</>
		);
	}

	if (phoneNumber === 'required') {
		return <>Wajib diisi</>;
	}

	if (statusApiError !== '') {
		return (
			<>
				Ups, sepertinya nomor ini <span>tidak memiliki</span> akun WhatsApp yang aktif. Coba nomor lain!
			</>
		);
	}

	return null;
};

export const ContactUsGenerateAgencyTextError = ({ agencyName }) => {
	if (agencyName === 'required') {
		return <>Wajib diisi</>;
	}
	return null;
};

export const ContactUsGenerateFullNameTextError = ({ fullName }) => {
	if (fullName === 'required') {
		return <>Wajib diisi</>;
	}
	return null;
};
