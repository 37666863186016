export const constanstMessage = {
	STORE_FULL_NAME: 'STORE_FULL_NAME',
	STORE_AGENCY_NAME: 'STORE_AGENCY_NAME',
	STORE_PHONE_NUMBER: 'STORE_PHONE_NUMBER',
};

export const setItem = (key, data) => {
	if (typeof window !== 'undefined') {
		localStorage.setItem(key, data);
	}
};

export const getItem = key => {
	if (typeof window !== 'undefined') {
		return localStorage.getItem(key);
	}

	return '';
};

export const removeItem = key => {
	if (typeof window !== 'undefined') {
		localStorage.removeItem(key);
	}
};

export const clear = () => {
	if (typeof window !== 'undefined') {
		localStorage.clear();
	}
};
